import {
	DataGrid,
	GridCellParams,
	GridColDef,
	GridColumnVisibilityModel,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueFormatterParams,
	GridValueGetterParams
} from "@mui/x-data-grid";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	InputAdornment,
	Link,
	Menu,
	MenuItem,
	TextField,
	Typography
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LockIcon from "@mui/icons-material/Lock";

import "./eventLogs.css";
import DeviceSelection from "../../components/DeviceSelection";
import LogTypeSelection from "../../components/LogTypeSelection";
import TimeRangeSelection from "../../components/TimeRangeSelection";
// import { ReactComponent as DashCarExitIcon } from "../../assets/icons/dash-car-exit.svg";
// import { ReactComponent as DashCarEntryIcon } from "../../assets/icons/dash-car-entry.svg";
// import { ReactComponent as DashBikeExitIcon } from "../../assets/icons/dash-bike-exit.svg";
import { ReactComponent as NoImageIcon } from "../../assets/icons/image-not-found-icon.svg";
// import { ReactComponent as DashBikeEntryIcon } from "../../assets/icons/dash-bike-entry.svg";
import { EVENT_LOGS_AUTO_REFRESH_INTERVAL, ROWS_PER_PAGE } from "../../utils/constants";
import { IDownloadEventLogsRequestData, IEventLogDetails, IEventLogRowDetails, LogFilterType } from "../../types";
import LogDetailsDialog from "./LogDetailsDialog";
import { useGetEventLogsQuery, useGetUserSettingsQuery } from "../../redux/reducers/eventLogs.reducer";
import {
	useGetCameraNamesQuery,
	useGetDevicesListQuery,
	useGetSocietyConfigQuery
} from "../../redux/reducers/settings.reducer";
import TablePagination from "../../components/TablePagination";
import { useAppDispatch, useAppSelector } from "../../redux";
import downloadServices from "../../redux/services/download.services";
import { setEventLogsFilters, setEventLogsType } from "../../redux/reducers/filters.reducer";
import { getFormattedDuration } from "../../utils/commonUtils";
import { SecureLoginTooltip } from "../../components/SecureLoginTooltip";
import { openSecureLoginDialog } from "../../redux/reducers/secureLoginDialog.reducer";
import TableSettingsDialog from "./TableSettingsDialog";
import VehicleTypeIcon from "../../components/VehicleTypeIcon";

export type ColumnVisibilityType = {
	[columnName: string]: {
		checked: boolean;
		visible: boolean;
		disabled: boolean;
	};
};

const COLUMNS = [
	"icon",
	"date",
	"time",
	"name",
	"vehicle_number",
	"plate_image",
	"is_entry",
	"camera_name",
	"device_name",
	"time_spent"
];

const DEFAULT_CHECKED_COLUMNS = ["icon", "date", "time", "vehicle_number"];

const EventsListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const PlateImage: FC<{ src: string; alt: string }> = ({ src, alt }) => {
	const [showError, setShowError] = useState<boolean>(false);

	if (!src || showError) {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
					gap: "0.5rem",
					width: "100%"
				}}
			>
				<NoImageIcon width={18} fill="#d5d5d5" stroke="#d5d5d5" />
				<Typography variant="body2" color="#999999">
					Image not available
				</Typography>
			</Box>
		);
	}

	return (
		<img
			src={src}
			alt={alt}
			style={{ width: "100%", height: "100%", objectFit: "contain" }}
			onError={() => setShowError(true)}
			onLoad={() => setShowError(false)}
		/>
	);
};

const EventLogs: FC = () => {
	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);
	const filtersState = useAppSelector((state) => state.filters);

	const { eventLogsFilters: filters, eventLogsType: selectedLogsFilter, timeFilter } = filtersState;

	const logsPageChanging = useRef<"next" | "previous" | null>(null);

	const [searchInput, setSearchInput] = useState<string>("");
	const [showLogDetailsDialog, setShowLogDetailsDialog] = useState<boolean>(false);
	const [selectedEventLogDetails, setSelectedEventLogDetails] = useState<IEventLogRowDetails | null>(null);
	const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
	const [showDownloadButtonLoader, setShowDownloadButtonLoader] = useState<boolean>(false);

	const [showTableSettingsDialog, setShowTableSettingsDialog] = useState<boolean>(false);
	const [columnVisibility, setColumnVisibility] = useState<ColumnVisibilityType>({});
	const [finalColumnVisibility, setFinalColumnVisibility] = useState<ColumnVisibilityType>({});

	const ColumnVisibilityModel = useMemo<GridColumnVisibilityModel>(() => {
		const model: GridColumnVisibilityModel = {};

		for (const [columnName, visibilityDetails] of Object.entries(finalColumnVisibility)) {
			model[columnName] = visibilityDetails.checked;
		}

		return model;
	}, [finalColumnVisibility]);

	// TABLE RENDERER STATES
	// LOCATION DROPDOWN
	const [locationDropdownAnchorElement, setLocationDropdownAnchorElement] = useState<HTMLElement | null>(null);
	const openLocationDropdown = Boolean(locationDropdownAnchorElement);

	// DIRECTION DROPDOWN
	const [directionDropdownAnchorElement, setDirectionDropdownAnchorElement] = useState<HTMLDivElement | null>(null);
	const directionDropdownOpen = Boolean(directionDropdownAnchorElement);

	// APIS
	const { data: getCameraNamesResponse } = useGetCameraNamesQuery();
	const { data: getDevicesListResponse } = useGetDevicesListQuery({});

	const { data: getUserSettingsResponse, refetch: refetchUserSettings } = useGetUserSettingsQuery(
		authState.user.username,
		{ skip: !authState.user.username }
	);

	const { data: getSocietyConfigResponse } = useGetSocietyConfigQuery();
	const societyConfig =
		getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
			? getSocietyConfigResponse.results[0]
			: null;

	const {
		data: getEventLogsResponse,
		isLoading: getEventLogsLoading,
		isFetching: getEventLogsFetching
	} = useGetEventLogsQuery(filters, {
		pollingInterval: isFilterApplied || authState.unauthorizedError ? 0 : EVENT_LOGS_AUTO_REFRESH_INTERVAL * 1000
	});

	const rowsData = useMemo(() => {
		if (!getEventLogsResponse) return [];

		const rowsDetails: IEventLogRowDetails[] = [];

		for (const eventLogItem of getEventLogsResponse.results) {
			const deviceDetails = getDevicesListResponse?.results.find((deviceItem) => deviceItem.id === eventLogItem.device);

			rowsDetails.push({
				id: eventLogItem.id,
				vehicle_type: eventLogItem.vehicle_type ?? 0,
				type: getEventLogType(eventLogItem),
				timestamp: eventLogItem.timestamp,
				name: getEventLogName(eventLogItem),
				vehicle_number: eventLogItem.number_plate,
				plate_image: eventLogItem.plate_img_uri,
				frame_image: eventLogItem.frame_img_uri ?? "",
				device_name: deviceDetails?.device_name ?? "",
				camera_name: eventLogItem.camera,
				unique_id: eventLogItem.unique_id,
				is_entry: eventLogItem.is_entry,
				flat_number:
					eventLogItem.owner?.flat_number ?? eventLogItem.visitor?.visited_flat_owner?.flat_number ?? "Unknown",
				address: eventLogItem.visitor?.address ?? "",
				comment: eventLogItem.visitor?.purpose ?? "-",
				contact_number: eventLogItem.visitor?.phone_number ?? "",
				purpose_of_visit: eventLogItem.visitor?.visit_type?.type_of ?? "",
				custom_field_data: eventLogItem.visitor?.custom_field_data ?? {},
				time_spent: eventLogItem.tat ? getFormattedDuration(eventLogItem.tat) : "--",
				bbox: eventLogItem.bbox ?? []
			});
		}

		if (logsPageChanging.current === "previous") {
			const logDetails = rowsDetails[rowsDetails.length - 1];
			handleOpenLogDetailsDialog(logDetails);
			logsPageChanging.current = null;
		} else if (logsPageChanging.current === "next") {
			const logDetails = rowsDetails[0];
			handleOpenLogDetailsDialog(logDetails);
			logsPageChanging.current = null;
		}

		return rowsDetails;
	}, [getDevicesListResponse, getEventLogsResponse]);

	const detailsPreviousButtonDisabled = useMemo<boolean>(() => {
		if (selectedEventLogDetails && typeof filters.page === "number" && filters.page === 1) {
			const selectedAlertIndex = rowsData.findIndex(
				(alertItem) => alertItem.unique_id === selectedEventLogDetails.unique_id
			);

			if (selectedAlertIndex === 0) {
				return true;
			}
		}

		return false;
	}, [filters.page, rowsData, selectedEventLogDetails]);

	const detailsNextButtonDisabled = useMemo<boolean>(() => {
		if (selectedEventLogDetails) {
			const totalCount = getEventLogsResponse?.count ?? 0;
			const totalPages = Math.ceil(totalCount / ROWS_PER_PAGE);

			if (typeof filters.page === "number" && filters.page === totalPages) {
				const selectedAlertIndex = rowsData.findIndex(
					(alertItem) => alertItem.unique_id === selectedEventLogDetails.unique_id
				);

				if (selectedAlertIndex === (totalCount % ROWS_PER_PAGE) - 1) {
					return true;
				}
			}
		}

		return false;
	}, [filters.page, getEventLogsResponse, rowsData, selectedEventLogDetails]);

	function handleOpenTableSettingsDialog() {
		setShowTableSettingsDialog(true);
	}

	function handleCloseTableSettingsDialog(success?: boolean) {
		setShowTableSettingsDialog(false);
		refetchUserSettings();
		if (success) {
			setFinalColumnVisibility(columnVisibility);
		} else {
			setColumnVisibility(finalColumnVisibility);
		}
	}

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleClearSearchInput() {
		setSearchInput("");
		setIsFilterApplied(true);

		dispatch(
			setEventLogsFilters((currentFiltersState) => {
				const updatedFilters = { ...currentFiltersState };
				delete updatedFilters.number_plate;
				updatedFilters.page = 1;
				return updatedFilters;
			})
		);
	}

	function handleSearchFormSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setIsFilterApplied(true);

		dispatch(
			setEventLogsFilters((currentFiltersState) => {
				const updatedFilters = { ...currentFiltersState };

				if (searchInput) updatedFilters.number_plate = searchInput;
				else delete updatedFilters.number_plate;

				updatedFilters.page = 1;

				return updatedFilters;
			})
		);
	}

	function handleChangeSelectedLogsFilter(updatedValue: LogFilterType | "") {
		dispatch(setEventLogsType(updatedValue ? updatedValue : null));
		setIsFilterApplied(true);

		dispatch(
			setEventLogsFilters((currentFiltersState) => {
				const updatedFilters = { ...currentFiltersState };
				updatedFilters.page = 1;

				if (!updatedValue) {
					delete updatedFilters.auth;
					delete updatedFilters.is_blacklisted;
					delete updatedFilters.is_unauthorized;
					delete updatedFilters.is_visitor;
				} else {
					switch (updatedValue) {
						case "authorized":
							updatedFilters.auth = true;
							delete updatedFilters.is_blacklisted;
							delete updatedFilters.is_unauthorized;
							delete updatedFilters.is_visitor;
							break;

						case "blacklisted":
							updatedFilters.is_blacklisted = true;
							delete updatedFilters.auth;
							delete updatedFilters.is_unauthorized;
							delete updatedFilters.is_visitor;
							break;

						case "unauthorized":
							updatedFilters.is_unauthorized = true;
							delete updatedFilters.auth;
							delete updatedFilters.is_blacklisted;
							delete updatedFilters.is_visitor;
							break;

						case "visitor":
							updatedFilters.is_visitor = true;
							delete updatedFilters.auth;
							delete updatedFilters.is_blacklisted;
							delete updatedFilters.is_unauthorized;
							break;

						default:
							delete updatedFilters.auth;
							delete updatedFilters.is_blacklisted;
							delete updatedFilters.is_unauthorized;
							delete updatedFilters.is_visitor;
							break;
					}
				}

				return updatedFilters;
			})
		);
	}

	function handleChangeDateValues(from: string, to: string) {
		const startDay = moment().startOf("day");
		const endDay = moment().endOf("day");

		if (moment(from).isSame(startDay) && moment(to).isSame(endDay)) {
			setIsFilterApplied(true);
		}

		dispatch(
			setEventLogsFilters((currentFiltersState) => ({
				...currentFiltersState,
				timestamp__gte: from,
				timestamp__lte: to,
				page: 1
			}))
		);
	}

	function handleChangeDevice(deviceId: number) {
		dispatch(
			setEventLogsFilters((currentFiltersState) => {
				const updatedFilters = { ...currentFiltersState };

				if (deviceId >= 0) updatedFilters.device = deviceId;
				else delete updatedFilters.device;

				updatedFilters.page = 1;

				return updatedFilters;
			})
		);
	}

	function handlePageChange(event: GridPaginationModel) {
		setIsFilterApplied(true);
		dispatch(
			setEventLogsFilters((currentFiltersState) => ({
				...currentFiltersState,
				page: event.page + 1
			}))
		);
	}

	function handleDownloadButtonClick() {
		setShowDownloadButtonLoader(true);

		const downloadFilters: IDownloadEventLogsRequestData = {
			timestamp__gte: filters.timestamp__gte,
			timestamp__lte: filters.timestamp__lte
		};

		if (typeof filters.device === "number") {
			downloadFilters.device = filters.device;
		}

		downloadServices
			.handleDownloadEventLogs(downloadFilters)
			.catch((error) => console.error("DOWNLOAD EVENT LOGS FAILED ::", error))
			.finally(() => setShowDownloadButtonLoader(false));
	}

	function handleOpenLogDetailsDialog(eventDetails: IEventLogRowDetails) {
		setSelectedEventLogDetails(eventDetails);
		setShowLogDetailsDialog(true);
	}

	function handleCloseLogDetailsDialog() {
		setShowLogDetailsDialog(false);
		setSelectedEventLogDetails(null);
	}

	function handleOpenSecureLoginDialog() {
		dispatch(openSecureLoginDialog());
	}

	function handleChangeToPreviousAlert(eventId: string) {
		const selectedAlertIndex = rowsData.findIndex((alertItem) => alertItem.unique_id === eventId);

		if (selectedAlertIndex < 0) return;

		if (selectedAlertIndex === 0) {
			if (filters.page && filters.page > 1) {
				logsPageChanging.current = "previous";
				dispatch(
					setEventLogsFilters((currentFilters) => {
						const updatedFilters = { ...currentFilters };
						if (typeof updatedFilters.page === "number") {
							updatedFilters.page = updatedFilters.page - 1;
						}
						return updatedFilters;
					})
				);
			}

			return;
		}

		const alertDetails = rowsData[selectedAlertIndex - 1];

		handleOpenLogDetailsDialog(alertDetails);
	}

	function handleChangeToNextAlert(eventId: string) {
		const selectedAlertIndex = rowsData.findIndex((alertItem) => alertItem.unique_id === eventId);

		if (selectedAlertIndex < 0) return;

		if (selectedAlertIndex === rowsData.length - 1) {
			const totalCount = getEventLogsResponse?.count ?? 0;
			const totalPages = Math.ceil(totalCount / ROWS_PER_PAGE);

			if (filters.page && filters.page < totalPages) {
				logsPageChanging.current = "next";
				dispatch(
					setEventLogsFilters((currentFilters) => {
						const updatedFilters = { ...currentFilters };
						if (typeof updatedFilters.page === "number") {
							updatedFilters.page = updatedFilters.page + 1;
						}
						return updatedFilters;
					})
				);
			}

			return;
		}

		const alertDetails = rowsData[selectedAlertIndex + 1];

		handleOpenLogDetailsDialog(alertDetails);
	}

	// TABLE RENDERER FUNCTIONS
	function getCamerasList(): string[] {
		// if (devicesList.length <= 0) return [];

		// if (typeof filters.device === "number") {
		// 	const selectedDeviceDetails = devicesList.find((deviceItem) => deviceItem.id === filters.device);
		// 	if (!selectedDeviceDetails) return [];

		// 	return Object.entries(selectedDeviceDetails.camera_names).map(([name, { is_entry: isEntry }]) => ({
		// 		name,
		// 		isEntry
		// 	}));
		// }

		// const camerasList: string[] = [];

		// for (const deviceItem of devicesList) {
		// 	for (const [cameraName, { is_entry }] of Object.entries(deviceItem.camera_names)) {
		// 		camerasList.push({
		// 			name: cameraName,
		// 			isEntry: is_entry
		// 		});
		// 	}
		// }

		// return camerasList;

		if (!getCameraNamesResponse || (getCameraNamesResponse && getCameraNamesResponse.length <= 0)) return [];

		if (typeof filters.device === "number") {
			const selectedDeviceDetails = getCameraNamesResponse.find(
				(deviceItem) => Number(deviceItem.device_id) === filters.device
			);

			if (!selectedDeviceDetails) return [];

			return selectedDeviceDetails.camera;
		}

		const camerasList: string[] = [];

		for (const deviceItem of getCameraNamesResponse) {
			camerasList.push(...deviceItem.camera);
		}

		return camerasList;
	}

	// LOCATION DROPDOWN
	function handleOpenLocationDropdown(event: MouseEvent<HTMLDivElement>) {
		setLocationDropdownAnchorElement(event.currentTarget);
	}

	function handleCloseLocationDropdown() {
		setLocationDropdownAnchorElement(null);
	}

	function handleChangeSelectedLocation(updatedValue: string) {
		handleCloseLocationDropdown();
		setIsFilterApplied(true);
		dispatch(
			setEventLogsFilters((currentFiltersState) => {
				const updatedFilters = { ...currentFiltersState };

				if (updatedValue) updatedFilters.camera = updatedValue;
				else delete updatedFilters.camera;

				updatedFilters.page = 1;

				return updatedFilters;
			})
		);
	}

	function renderLocationDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper center"
					id="location-dropdown"
					aria-controls={openLocationDropdown ? "location-dropdown-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={openLocationDropdown}
					onClick={handleOpenLocationDropdown}
				>
					<Typography variant="subtitle2" textTransform="capitalize">
						{filters.camera ? filters.camera : "Location"}
					</Typography>

					{openLocationDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="location-dropdown-menu"
					anchorEl={locationDropdownAnchorElement}
					open={openLocationDropdown}
					onClose={handleCloseLocationDropdown}
					MenuListProps={{ "aria-labelledby": "location-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedLocation("")} sx={{ minWidth: 130 }}>
						<em>All</em>
					</MenuItem>

					{getCamerasList().map((cameraName, index) => (
						<MenuItem key={index} onClick={() => handleChangeSelectedLocation(cameraName)}>
							{cameraName}
						</MenuItem>
					))}
				</Menu>
			</Box>
		);
	}

	// DIRECTION DROPDOWN
	function handleOpenDirectionDropdown(event: MouseEvent<HTMLDivElement>) {
		setDirectionDropdownAnchorElement(event.currentTarget);
	}

	function handleCloseDirectionDropdown() {
		setDirectionDropdownAnchorElement(null);
	}

	function handleChangeSelectedDirection(updatedValue: "entry" | "exit" | null) {
		dispatch(
			setEventLogsFilters((currentFilters) => {
				const updatedFilters = { ...currentFilters };

				if (updatedValue) updatedFilters.is_entry = updatedValue === "entry";
				else delete updatedFilters.is_entry;

				return updatedFilters;
			})
		);

		handleCloseDirectionDropdown();
	}

	function renderDirectionDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper center"
					id="direction-dropdown"
					aria-controls="direction-dropdown-menu"
					aria-haspopup="true"
					aria-expanded={directionDropdownOpen}
					onClick={handleOpenDirectionDropdown}
				>
					<Typography variant="subtitle2" textTransform="capitalize">
						{typeof filters.is_entry === "boolean" ? (filters.is_entry ? "Entry" : "Exit") : "Direction"}
					</Typography>

					{directionDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="direction-dropdown-menu"
					anchorEl={directionDropdownAnchorElement}
					open={directionDropdownOpen}
					onClose={handleCloseDirectionDropdown}
					MenuListProps={{ "aria-labelledby": "direction-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedDirection(null)} sx={{ minWidth: 85 }}>
						<em>All</em>
					</MenuItem>

					<MenuItem onClick={() => handleChangeSelectedDirection("entry")}>Entry</MenuItem>
					<MenuItem onClick={() => handleChangeSelectedDirection("exit")}>Exit</MenuItem>
				</Menu>
			</Box>
		);
	}

	function renderMaskedDataColumnHeader(headerName: string) {
		const TooltipTitle: ReactNode =
			authState.user.secure_login_enabled_society && !authState.user.secure_login_enabled_user ? (
				"You don't have access"
			) : (
				<Link onClick={handleOpenSecureLoginDialog}>Click here to login securely to view details</Link>
			);

		return (
			<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", width: "100%", height: "100%" }}>
				<Typography variant="subtitle2" textTransform="capitalize">
					{headerName}
				</Typography>

				{(authState.user.secure_login_enabled_society || authState.user.secure_login_enabled_user) &&
				authState.user.login_type !== "secure" ? (
					<SecureLoginTooltip title={TooltipTitle}>
						<LockIcon htmlColor="#0000008F" sx={{ fontSize: "16px" }} />
					</SecureLoginTooltip>
				) : null}
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{
			field: "icon",
			headerName: "",
			width: 75,
			sortable: false,
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			renderCell: (params: GridRenderCellParams<IEventLogRowDetails>) => (
				<Box className="event-vehicle-type-icon">
					<Box className={`event-type-identifier-color ${params.row.type}`} />

					<VehicleTypeIcon vehicleType={params.row.vehicle_type} width={20} fill="var(--color-primary-main)" />

					{/* {params.row.is_entry ? (
						params.row.vehicle_type === 0 ? (
							<DashCarEntryIcon width={20} />
						) : (
							<DashBikeEntryIcon width={20} />
						)
					) : params.row.vehicle_type === 0 ? (
						<DashCarExitIcon width={20} />
					) : (
						<DashBikeExitIcon width={20} />
					)} */}
				</Box>
			)
		},
		{
			field: "date",
			headerName: "Date",
			flex: 1,
			minWidth: 110,
			sortable: false,
			align: "center",
			headerAlign: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			valueGetter: (params: GridValueGetterParams<IEventLogRowDetails>) =>
				moment(params.row.timestamp).format("MMM D, YYYY")
		},
		{
			field: "time",
			headerName: "Time",
			flex: 1,
			minWidth: 110,
			sortable: false,
			align: "center",
			headerAlign: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			valueGetter: (params: GridValueGetterParams<IEventLogRowDetails>) =>
				moment(params.row.timestamp).format("hh:mm:ss A")
		},
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 150,
			align: "center",
			headerAlign: "center",
			sortable: false,
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			renderHeader: () => renderMaskedDataColumnHeader("Name")
		},
		{
			field: "vehicle_number",
			headerName: "Number Plate",
			flex: 1.5,
			minWidth: 150,
			sortable: false,
			headerAlign: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			renderCell: (params: GridRenderCellParams<IEventLogRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Typography
						variant="body2"
						textAlign="center"
						sx={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis" }}
					>
						{params.value}
					</Typography>
				</Box>
			)
		},
		{
			field: "plate_image",
			headerName: "Plate Image",
			width: 175,
			sortable: false,
			headerAlign: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			renderCell: (params: GridRenderCellParams<IEventLogRowDetails, string>) => (
				<Box sx={{ width: "100%", height: "100%", paddingY: 0.25 }}>
					<PlateImage src={params.value ?? ""} alt={params.row.vehicle_number} />
				</Box>
			)
		},
		{
			field: "is_entry",
			headerName: "Direction",
			flex: 1,
			minWidth: 105,
			sortable: false,
			align: "center",
			renderHeader: renderDirectionDropdown,
			valueFormatter: (params: GridValueFormatterParams<boolean>) => (params.value ? "Entry" : "Exit"),
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : ""
		},
		{
			field: "camera_name",
			headerName: "Location",
			flex: 1,
			minWidth: 120,
			sortable: false,
			align: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			renderHeader: renderLocationDropdown
		},
		{
			field: "device_name",
			headerName: "Device",
			flex: 1,
			minWidth: 120,
			sortable: false,
			align: "center",
			headerAlign: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : ""
		},
		{
			field: "time_spent",
			headerName: "Time Spent",
			flex: 1,
			minWidth: 130,
			sortable: false,
			align: "center",
			headerAlign: "center",
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : ""
		}
	];

	function getColumnsData() {
		const updatedColumnsData = [...columnsData];

		if (societyConfig && societyConfig.custom_fields && societyConfig.custom_fields.length > 0) {
			for (const customFieldItem of societyConfig.custom_fields) {
				if (customFieldItem.name === "Purpose of Visit") continue;

				updatedColumnsData.push({
					field: customFieldItem.name,
					headerName: customFieldItem.name,
					flex: 1,
					minWidth: 130,
					sortable: false,
					align: "center",
					headerAlign: "center",
					cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
						selectedLogsFilter !== "blacklisted" ? params.row.type : "",
					valueGetter: (params: GridValueGetterParams<IEventLogRowDetails>) =>
						params.row.custom_field_data[customFieldItem.name]
				});
			}
		}

		updatedColumnsData.push({
			field: "info",
			headerName: "",
			width: 50,
			sortable: false,
			cellClassName: (params: GridCellParams<IEventLogRowDetails>) =>
				selectedLogsFilter !== "blacklisted" ? params.row.type : "",
			renderCell: (params: GridRenderCellParams<IEventLogRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton size="small" onClick={() => handleOpenLogDetailsDialog(params.row)}>
						<InfoOutlinedIcon fontSize="inherit" color="info" />
					</IconButton>
				</Box>
			)
		});

		return updatedColumnsData;
	}

	function getEventLogType(eventLogItem: IEventLogDetails): LogFilterType {
		if (eventLogItem.auth) return "authorized";
		if (eventLogItem.is_blacklisted) return "blacklisted";
		if (!!eventLogItem.visitor) return "visitor";
		return "unauthorized";
	}

	function getEventLogName(eventLogItem: IEventLogDetails): string {
		if (eventLogItem.is_blacklisted) return "Unknown";
		if (!!eventLogItem.visitor) return eventLogItem.visitor.name;

		// if (eventLogItem.auth) {
		if (eventLogItem.owner) return eventLogItem.owner.name;
		return "Unknown";
		// }

		// return "Unknown";
	}

	useEffect(() => {
		if (timeFilter && timeFilter.start_time && timeFilter.end_time) {
			handleChangeDateValues(timeFilter.start_time, timeFilter.end_time);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeFilter]);

	useEffect(() => {
		if (filtersState.eventLogsFilters.number_plate) {
			setSearchInput(filtersState.eventLogsFilters.number_plate);
		}
	}, [filtersState.eventLogsFilters]);

	useEffect(() => {
		let columns: ColumnVisibilityType = {};

		if (getUserSettingsResponse) {
			for (const columnName of COLUMNS) {
				columns[columnName] = {
					checked: getUserSettingsResponse?.user_settings.columns?.[columnName] ?? true,
					disabled: false,
					visible: true
				};

				if (columns[columnName] && columns[columnName].checked) {
					if (DEFAULT_CHECKED_COLUMNS.includes(columnName)) {
						columns[columnName] = {
							checked: true,
							disabled: true,
							visible: true
						};
					}
				}
			}

			if (societyConfig && societyConfig.custom_fields && societyConfig.custom_fields.length > 0) {
				for (const customFieldItem of societyConfig.custom_fields) {
					if (customFieldItem.name === "Purpose of Visit") continue;

					if (DEFAULT_CHECKED_COLUMNS.includes(customFieldItem.name)) {
						columns[customFieldItem.name] = {
							checked: true,
							disabled: true,
							visible: true
						};
					} else {
						columns[customFieldItem.name] = {
							checked: getUserSettingsResponse?.user_settings.columns?.[customFieldItem.name] ?? true,
							disabled: false,
							visible: true
						};
					}
				}
			}
		}

		setColumnVisibility(columns);
		setFinalColumnVisibility(columns);
	}, [getUserSettingsResponse, societyConfig]);

	return (
		<Box className="event-logs-screen-wrapper">
			<Box className="event-logs-screen-header-wrapper">
				<DeviceSelection defaultValue={filters.device} onChange={handleChangeDevice} />

				<TimeRangeSelection customOnly useUrlParams />
			</Box>

			<Box className="event-logs-table-section">
				<Box className="event-logs-table-header-wrapper">
					<LogTypeSelection
						defaultValue={selectedLogsFilter ? selectedLogsFilter : undefined}
						onChange={handleChangeSelectedLogsFilter}
					/>

					<Box
						className="event-logs-table-actions-wrapper"
						component="form"
						noValidate
						onSubmit={handleSearchFormSubmit}
					>
						<Button variant="outlined" color="primary" onClick={handleOpenTableSettingsDialog}>
							Table Settings
						</Button>

						<TextField
							size="small"
							variant="filled"
							placeholder="Search using number plate"
							value={searchInput}
							onChange={handleChangeSearchInput}
							InputProps={{
								hiddenLabel: true,
								disableUnderline: true,
								classes: { root: "event-logs-search-input" },
								endAdornment: (
									<InputAdornment position="end">
										{filters.number_plate ? (
											<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
												<CloseIcon fontSize="inherit" />
											</IconButton>
										) : null}

										<IconButton edge="end" color="primary" size="small" type="submit">
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>

						<IconButton color="primary" onClick={handleDownloadButtonClick} disabled={showDownloadButtonLoader}>
							{showDownloadButtonLoader ? <CircularProgress size={20} /> : <DownloadIcon />}
						</IconButton>
					</Box>
				</Box>

				<Box className="event-logs-table-wrapper">
					<DataGrid
						columns={getColumnsData()}
						rows={rowsData}
						disableRowSelectionOnClick
						disableColumnMenu
						rowSpacingType="border"
						density="compact"
						columnHeaderHeight={80}
						pageSizeOptions={[ROWS_PER_PAGE]}
						slots={{ pagination: EventsListPagination }}
						hideFooter={(getEventLogsResponse?.count ?? 0) <= ROWS_PER_PAGE}
						slotProps={{ footer: { sx: { justifyContent: "center" } } }}
						paginationMode="server"
						paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
						onPaginationModelChange={handlePageChange}
						rowCount={getEventLogsResponse?.count ?? 0}
						loading={
							(isFilterApplied ||
								!getEventLogsResponse ||
								(getEventLogsResponse && getEventLogsResponse.results.length <= 0)) &&
							(getEventLogsLoading || getEventLogsFetching)
						}
						classes={{
							columnSeparator: "event-logs-table-column-separator",
							columnHeader: `event-logs-table-column-header ${selectedLogsFilter}`,
							root: "event-logs-table-root"
						}}
						columnVisibilityModel={ColumnVisibilityModel}
					/>
				</Box>
			</Box>

			<LogDetailsDialog
				open={showLogDetailsDialog}
				eventLogDetails={selectedEventLogDetails}
				previousButtonDisabled={detailsPreviousButtonDisabled}
				nextButtonDisabled={detailsNextButtonDisabled}
				pageChangeLoading={!!logsPageChanging.current && (getEventLogsLoading || getEventLogsFetching)}
				onClose={handleCloseLogDetailsDialog}
				onPrevious={handleChangeToPreviousAlert}
				onNext={handleChangeToNextAlert}
			/>

			<TableSettingsDialog
				open={showTableSettingsDialog}
				columns={getColumnsData()}
				columnVisibility={columnVisibility}
				onClose={handleCloseTableSettingsDialog}
				setColumnVisibility={setColumnVisibility}
			/>
		</Box>
	);
};

export default EventLogs;
